<style>

</style>
<template>
    <div></div>
</template>
<script>
    import request from "../plugins/axios";

    export default {
        name: "",
        components: {},

        data() {
            return {

            };
        },
        created() {
            let that = this

        },

        methods: {

        },
    };
</script>
